import isEmpty from 'lodash/isEmpty';
import axiosInstance from '../axiosInstance';
import getUrlAppendixStringByKeys from './common';
import {
  GET_DATA_CHART_SERVICE_URL,
  ENTITY_ID_URL_APPENDIX,
  FROM_DATE_URL_APPENDIX,
  TO_DATE_URL_APPENDIX,
  DATA_KEY_URL_APPENDIX,
  GET_DATA_FOR_CHARTS_SERVICE_URL,
  GET_VECTOR_DATA_CHART_SERVICE_URL,
  GET_LINKED_ASTRONAUT_ENTITY_ID_APPENDIX,
  GET_LINKED_ASTRONAUT_GRAPH_NAME_APPENDIX,
  GET_DATA_FOR_CHARTS_EVENTS_SERVICE_URL,
  GET_EVENTS_LEVEL_MENU_OPTIONS,
  GET_VECTOR_EVENTS_SERVICE_URL,
} from './config';
import { VectorDataChartResponse } from '../../reducers/types/vectorDataTypes';
import { Event } from '../../containers/EventsPageVDE/types';

export const api = {
  async getMilkJarWeightDetails(entityId, startDate, endDate) {
    let dataForDataKeyApiUrl = GET_DATA_CHART_SERVICE_URL;

    let dataKeysAppendix = `${DATA_KEY_URL_APPENDIX}Milk%20Yield`;

    if (dataKeysAppendix) {
      dataKeysAppendix = `?${dataKeysAppendix}`;
      dataForDataKeyApiUrl +=
        dataKeysAppendix + ENTITY_ID_URL_APPENDIX + entityId;
    }
    dataForDataKeyApiUrl +=
      FROM_DATE_URL_APPENDIX + startDate + TO_DATE_URL_APPENDIX + endDate;
    return axiosInstance.get(dataForDataKeyApiUrl);
  },

  async getChartDetails(
    entityId,
    startDate,
    endDate,
    dataKeys,
    linkedEntityId,
  ) {
    let dataForDataKeyApiUrl = GET_DATA_CHART_SERVICE_URL;

    // #region get all data keys and separate them into 2 arrays - first astronaut data keys and linked keys
    const firstAstronautDataKeys: any[] = [];
    const linkedKeys: any[] = [];
    if (!isEmpty(dataKeys)) {
      dataKeys.forEach((currentDataKey) => {
        const isCurrentDataKeyLinked = currentDataKey.indexOf('(') !== -1;
        if (isCurrentDataKeyLinked) {
          linkedKeys.push(currentDataKey.split(' (')[0]);
        } else {
          firstAstronautDataKeys.push(currentDataKey);
        }
      });
    }
    // #endregion

    let dataKeysAppendix = getUrlAppendixStringByKeys(
      DATA_KEY_URL_APPENDIX,
      firstAstronautDataKeys,
      true,
    );
    let linkedKeysAppendix = getUrlAppendixStringByKeys(
      GET_LINKED_ASTRONAUT_GRAPH_NAME_APPENDIX,
      linkedKeys,
      true,
    );
    if (dataKeysAppendix && dataKeysAppendix.length > 0) {
      dataKeysAppendix = `?${dataKeysAppendix}`;
      linkedKeysAppendix = `&${linkedKeysAppendix}`;
      dataForDataKeyApiUrl +=
        dataKeysAppendix + ENTITY_ID_URL_APPENDIX + entityId;
    } else {
      linkedKeysAppendix = `?${linkedKeysAppendix}`;
    }
    if (linkedEntityId && linkedKeys.length > 0) {
      dataForDataKeyApiUrl += linkedKeysAppendix;
      dataForDataKeyApiUrl +=
        GET_LINKED_ASTRONAUT_ENTITY_ID_APPENDIX + linkedEntityId;
    }
    dataForDataKeyApiUrl +=
      FROM_DATE_URL_APPENDIX + startDate + TO_DATE_URL_APPENDIX + endDate;
    return axiosInstance.get(dataForDataKeyApiUrl);
  },

  async getConfigDataForCharts(entityId) {
    const getChartDataForEntityUrl = GET_DATA_FOR_CHARTS_SERVICE_URL + entityId;
    return axiosInstance.get(getChartDataForEntityUrl);
  },

  async getVectorDataForCharts(
    entityId: string,
    startDate: string,
    endDate: string,
  ) {
    const getVectorChartDataForEntityUrl =
      GET_VECTOR_DATA_CHART_SERVICE_URL +
      entityId +
      FROM_DATE_URL_APPENDIX +
      startDate +
      TO_DATE_URL_APPENDIX +
      endDate;

    return axiosInstance.get<VectorDataChartResponse>(
      getVectorChartDataForEntityUrl,
    );
  },

  async getEventsDataForCharts(entityId, startDate, endDate) {
    const getEventsChartDataForEntityUrl =
      GET_DATA_FOR_CHARTS_EVENTS_SERVICE_URL +
      entityId +
      FROM_DATE_URL_APPENDIX +
      startDate +
      TO_DATE_URL_APPENDIX +
      endDate;
    return axiosInstance.get(getEventsChartDataForEntityUrl);
  },

  async getVectorEvents(entityId: string, startDate: string, endDate: string) {
    const getVectorChartDataForEntityUrl =
      GET_VECTOR_EVENTS_SERVICE_URL +
      entityId +
      FROM_DATE_URL_APPENDIX +
      startDate +
      TO_DATE_URL_APPENDIX +
      endDate;

    return axiosInstance.get<{ events: Event[] }>(
      getVectorChartDataForEntityUrl,
    );
  },

  async getEventsLevelOptions() {
    return axiosInstance.get(GET_EVENTS_LEVEL_MENU_OPTIONS);
  },
};

export type DataChartsApiType = typeof api;
