/* eslint-disable no-useless-return */
/* eslint-disable no-console */

import { BrowserCacheLocation, InteractionType } from '@azure/msal-browser';
import environment from '../environment';

const appId =
  environment() !== 'Production'
    ? '0113c979-7d7c-4fb2-a65b-429d7664c124'
    : '1d5abb57-e388-4bd4-9eb3-e42f52306020';

const tenantId = 'ad1af34e-72ec-4617-8123-2280b7b426b6';

const secret =
  environment() !== 'Production'
    ? 'Ndm8Q~E.DRcHbUIWPJtbm0AyCjfFJDYJ9tEuIbcj'
    : 't8s8Q~KZXsZ6lf0ksPiKmm-pcCpfA.dCcFbMab14';

const redirectUri =
  environment() === 'Development'
    ? 'https://localhost:3000'
    : environment() === 'Staging'
    ? 'https://ltda-stage.lely.cloud/'
    : 'https://ltda.lely.cloud/';

const apiConfigScope =
  environment() === 'Development'
    ? 'api://d4981bb6-d683-468f-9c60-ed70ab5ef559/Sense.Client'
    : 'api://db542874-d023-4d25-8177-b1f9769df8b7/Sense.Client';

export const msalConfig = {
  auth: {
    clientId: appId,
    authority: `https://login.microsoftonline.com/${tenantId}`,
    redirectUri,
    secret,
    postLogoutRedirectUri: redirectUri,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage, // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};
const graphConfig = {
  scopes: ['User.Read'],
  urls: {
    getMe: 'https://graph.microsoft.com/v1.0/me',
  },
};

const apiConfig = {
  scopes: [apiConfigScope],
  urls: {
    getMe: 'https://localhost:3000',
  },
};

const loginMode = InteractionType.Redirect; // Redirect or Popup

const authConfig = {
  msal: msalConfig,
  graph: graphConfig,
  api: apiConfig,
  loginMode,
};
export default authConfig;
